import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import MediaLayout from "../../components/media-layout"
import Preferences from "../preference/preferences";

import {useIntl, Link} from "gatsby-plugin-intl"

class BodyClass extends React.Component {

  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}

const EditPreferences = () => {
  const intl = useIntl();
  const pageContent = () => {
    return(
      <div className="col-lg-8">
        <Link to="/profile/settings" className="black mt-0 back-link single-page"><span className="icon icon-chevron"></span><strong>
        {intl.formatMessage({id: "generic.nav_buttons.back_button"})}
        </strong></Link>
        <Preferences navigateTo='/profile/settings' />
      </div>
    )
  };

  return(
    <Layout>
      <SEO title={intl.formatMessage({ id: "pages_meta_title.profile.edit-preferences" })} />
      <BodyClass name="large-wrapper" />

      <MediaLayout children={pageContent()} currentPage="edit-preferences" />

      <Link to="/contact/how-can-help-you" className="btn-contact"><span className="icon-questions"></span></Link>

    </Layout>
  )
};

export default EditPreferences
